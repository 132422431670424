<template>
    <v-app>
      <!-- Navbar -->
      <v-app-bar
        v-if="hasToken"
        app
        class="nav-bar justify-space-between align-center"
        dark
      >
        <!-- Logo alineado a la izquierda -->
        <v-img
          src="@/assets/icon_white_new.png"
          alt="Logo"
          contain
          class="logo"
          @click="redirectToHome"
        ></v-img>
  
        <!-- Botón de login alineado a la derecha -->
        <v-btn
          text
          @click="redirectToLogin"
          style="position: absolute; right: 0;"
        >
         Ingresar
        </v-btn>
      </v-app-bar>
  
      <!-- Contenido Principal -->
        <v-container v-if="!error">
            <div class="text-center mt-5">
                <h1 class="text-center" style="color:#1d1d1f;">Estado de cuenta</h1>
                <h2 style="color:#1d1d1f;">{{ week.from }} al {{ week.to }}</h2>
            </div>
            <!-- <v-divider></v-divider> -->
            <v-row class="pt-6 pb-6 mt-5">
                <v-col cols="12" class="d-flex justify-space-between align-center">
                    <div>
                        <div class="text-h7" style="color:#1d1d1f;">Total a {{ hasToken ? "pagar" : "cobrar" }} esta semana <b>{{ getTotal | currency }}</b></div>
                        <small>*Si se ha realizado un pago, puede demorar unos minutos en verse reflejado aquí.</small>
                    </div>
                    <v-btn
                        outlined
                        small
                        style="color:#1d1d1f;"
                        @click="exportStatement"
                        :disabled="loading"
                    >
                        <v-icon left v-if="!loading">mdi-file-excel</v-icon>
                        {{ loading ? "Exportando..." : "Exportar" }}
                    </v-btn>
                </v-col>
            </v-row>
            <!-- Mostrar tabla o mensaje -->
            <v-card v-if="payments.length > 0" class="elevation-0 mt-5">
                <v-data-table
                    :headers="headers"
                    :items="payments"
                    class="elevation-1"
                    hide-default-footer
                    disable-pagination
                >
                    <template v-slot:[`item.amount`]="{ item }">
                    <span><b>{{ parseFloat(item.amount) | currency }}</b></span>
                    </template>
                </v-data-table>
            </v-card>
            <div v-else class="d-flex align-end justify-center" style="margin-top: 5em; color:#1d1d1f;" >
                <v-alert
                    outlined
                    style="color:#1d1d1f !important; background: #1d1d1f;"
                    block
                >
                    No hay pagos pendientes en este período.
                </v-alert>
            </div>
        </v-container>
        <v-container  v-else>
            <v-alert
                outlined
                style="color:#1d1d1f !important;"
                type="info"   
                block
            >
                Ocurrio un error
            </v-alert>
        </v-container>
    </v-app>
</template>
  
<script>
  import CustomerService from "@/services/customerService";
  import SupplierService from "@/services/supplierService";
  
    export default {
        data() {
        return {
            hasToken: false,
            token: null,
            logo: require("@/assets/logo.png"),
            week: {
                from: "",
                to: "",
            },
            payments: [],
            headers: [
            { text: "ID", value: "id" },
            { text: "", value: "name" },
            { text: "Monto", value: "amount" },
            { text: "Fecha de compra", value: "purchase_date" },
            { text: "Fecha de vencimiento", value: "expiration_date" },
            ],
            error: null,
            loading: false
        };
        },
        async created() {
        const token = this.$route.query.token;
        this.token = token
        // Determinar si mostrar el navbar
        this.hasToken = Boolean(token);
    
        // Actualizar dinámicamente el texto del header según si hay token
        this.headers[1].text = this.hasToken ? "Proveedor" : "Cliente";
    
        if (token) {
            // Constructor
            await this.fetchAccountStatusByToken(token);
        } else {
            // Supplier
            const isAuthenticated = localStorage.getItem("token");
    
            if (!isAuthenticated) {
            this.$router.push({ name: "login" });
            return;
            }
    
            await this.fetchAccountStatusForSupplier();
        }
        },
        methods: {
            redirectToHome() {
                this.$router.push({ name: "main" });
            },
            redirectToLogin() {
                this.$router.push({ name: "login" });
            },
            async fetchAccountStatusByToken(token) {
                try {
                    const response = await CustomerService.getAccountStatusByToken(token);
                    if (response.ok) {
                        const data = await response.json();
                        this.week = data.week;
                        this.payments = data.data.map((payment) => ({
                        ...payment,
                        name: payment.supplier,
                        }));
                    } else {
                        this.error = "Ocurrio un error"
                        console.error(
                        "Error al obtener el estado de cuenta del constructor:",
                        response.status
                        );
                    }
                } catch (error) {
                console.error("Error en la solicitud:", error);
                }
            },
            async fetchAccountStatusForSupplier() {
                try {
                    const response = await SupplierService.getAccountStatus();
                    if (response.ok) {
                        const data = await response.json();
                        this.week = data.week;
                        this.payments = data.data.map((payment) => ({
                        ...payment,
                        name: payment.customer,
                        }));
                    } else {
                        this.error = "Ocurrio un error"
                        console.error(
                        "Error al obtener el estado de cuenta del proveedor:",
                        response.status
                        );
                    }
                } catch (error) {
                    console.error("Error en la solicitud:", error);
                }
            },
            
            async exportStatement() {
                this.loading = true
                try {
                    
                    let response = null
                    
                    if (this.hasToken) {
                        response = await CustomerService.exportAccountStatus(this.token);
                    } else {
                        response = await SupplierService.exportAccountStatus();
                    }

                    if (response.ok) {
                        const blob = await response.blob();
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = 'estado_de_cuenta.xlsx';
                        a.click();
                        window.URL.revokeObjectURL(url);
                    } else {
                       console.log("error")
                    }
                } catch (error) {
                    console.error("Error en la solicitud:", error);
                } finally {
                    this.loading = false
                }
            }
        },

        computed: {
            getTotal () {
                return this.payments.reduce((sum, payment) => sum + Number(payment.amount), 0);
            }
        },

        filters: {
            currency(value) {
                
                var formatter = new Intl.NumberFormat('es-MX', {
                    style: 'currency',
                    currency: 'MXN'
                });
                
                return formatter.format(value);
            }
        }
    };
</script>
  
<style scoped>
    /* Navbar estilos */
    .nav-bar {
        background-color: #732c9c !important; /* Color morado principal */
        padding: 0 16px;
        height: 64px;
    }
  
    .nav-bar .logo {
        width: 50px;
        height: 50px;
        cursor: pointer;
        position: absolute;
        left: 0;
    }
    
    /* Botón de login */
    .nav-bar .v-btn {
        color: white !important;
        font-weight: bold;
    }

    .container{
        margin-top: 4rem;
    }
    /* Títulos */
    h2, h1 {
        color: #732c9c;
    }
    
    .logo{
        margin-right: 3%;
    }

    .v-app-bar {
        background: #1d1d1f !important;
    }

    .v-app-bar .v-btn {
        color: #fff !important;
    }


    /* Tabla estilos */
    /* .v-data-table {
        background-color: #f4f4f4;
    } */
    
    /* .v-alert {
        background-color: #e3f2fd !important;
        color: #1976d2 !important;
        border-top: 4px solid #1976d2 !important;
    } */
</style>
  