<template>
 <v-main>
    <v-data-table
        :headers="headers"
        :items="items"
        hide-default-footer
        class="elevation-1"
        :loading="loading"
        loading-text="Cargando..."
    >
        <template v-slot:[`item.days`]="{ item }">
            <v-chip
                class="ma-2 deep-purple white--text"
                small
            >
                Días solicitados:  <b>{{ item.days }}</b>
            </v-chip>
        </template>
         <template v-slot:[`item.accepted`]="{ item }">
            <v-chip
                small
                :class="`ma-2 ${item.accepted ===  null ? 'warning' : (item.accepted == true ? 'success' : 'error')} white--text`"
            >
                <b>{{ item.accepted ===  null ? "Pendiente" : (item.accepted == true ? "Aceptado" : "Rechazado") }}</b>
            </v-chip>
        </template>
        <template v-slot:[`item.actions`]="{ item }" v-if="$UserManager.getMainRole() == 'supplier'">
            <v-row>
                <v-col cols="12" class="d-flex justify-space-around">
                    <v-btn
                        color="success"  
                        @click="showConfirm(item.id, true)"
                        small
                    >
                    Aprobar 
                    </v-btn>
                    <v-btn
                        color="error"
                        @click="showConfirm(item.id, false)"
                        small
                    >
                    Rechazar 
                    </v-btn>    
                </v-col>
            </v-row>
        </template>
    </v-data-table>
    <div class="col col-12 text-center mt-2">
        <v-pagination
        v-model="page"
        :length="totalPages"
        :total-visible="6"
        circle
        color="black"
        ></v-pagination>
    </div>
    <DialogAlert :visible="showDialogAlert" :message="dialogAlertContent" @handleCloseDialogAlert="handleCloseDialogAlert"/>
    <DialogComponent :visible="showDialog" @close="handleCloseDialogAlert" :title="dialogAlertContent.title" :text="dialogAlertContent.text" :text_button="dialogAlertContent.text_button" @handleAction="dialogAlertContent.action"/>
 </v-main>
</template>

<script>
import PaymentService from '../services/paymentService';
import DialogAlert from "../components/DialogAlert"
import DialogComponent from "../components/DialogComponent"

export default {

    components: {
        DialogAlert,
        DialogComponent
    },

    data() {
      return {
            items: [],
            page: 1,
            totalPages: null,
            headers:  [
                { text: "ID de pago", value: "payment_id"}, 
                { text: "Cliente", value: "customer_business_name"}, 
                { text: "Monto", value: "amount"}, 
                { text: "Plazo", value: "term"}, 
                { text: "Fecha de vencimiento", value: "expiration_date"},
                { text: "Días solicitados", value: "days"},
                { text: "Estado", value: "accepted"}, 
                { text: 'Acciones', value: 'actions', sortable: false },
            ],
            loading: false,
            showDialogAlert: false,
            showDialog: false,
            dialogAlertContent: {
                title: "",
                success: "",
                text: "",
                action: () => {}    
            }, 
        }
    },

    mounted() {
        this.get()
    },

    methods: {
        async get() {

            this.loading = true

            let response = null

            if (this.$UserManager.getMainRole() == "supplier") {
                response = await PaymentService.getTermExtensionRequest("pending", this.page)
            } else {
                response = await PaymentService.getTermExtensionRequest("all", this.page, "updated")
            }

            const result = await response.json()

            this.items = result.data
            this.totalPages = result.last_page
            this.loading = false

            console.log(this.items, "items")
        },

        async handleRequestExtendTerm(term_extension_request_id, accepted) {
            const response = await PaymentService.handleRequestExtendTerm(term_extension_request_id, accepted)
            const result = await response.json()

            // this.dialogAlertContent.title = accepted ? "Aceptado" : "Rechazado"
            this.dialogAlertContent.text = result.msg
            this.dialogAlertContent.success = result.success
            this.showDialogAlert = true

            this.get()
        },

        showConfirm (id, accepted) {
            this.dialogAlertContent.title = "Confirmar"
            this.dialogAlertContent.text = "¿Desea " + (accepted ? "aceptar" : "rechazar") + " la extensión de plazo?"
            this.dialogAlertContent.action = () => this.handleRequestExtendTerm(id, accepted)
            this.showDialog = true
        },

        handleCloseDialogAlert () {
            this.showDialog = false
            this.showDialogAlert = false
            this.dialogAlertContent.title = ""
            this.dialogAlertContent.success = ""
            this.dialogAlertContent.text = ""
        },
    },
    
    watch: {
        page () {
            this.get()
        }
    },

    filters: {
      currency(value) {
          
          var formatter = new Intl.NumberFormat('es-MX', {
              style: 'currency',
              currency: 'MXN'
          });
          
          return formatter.format(value);
      }
    }
}
</script>

<style>
    .v-application .primary {
        background-color: rgb(115, 44, 156) !important;
        border-color: rgb(115, 44, 156) !important;
    }
</style>
