<template>
    <v-main>
        <v-container
            class="py-8 px-6"
        >
            <v-row>
                <v-col
                lg="6"
                offset-lg="3"
                md="12"
                >
                    <v-card elevation="1" radius="20" class="pa-6 rounded-xl">
                        <v-img
                            max-height="120"
                            max-width="200"
                            src="@/assets/logo_new.png"
                            class="mx-auto"
                        ></v-img>
                        <div v-if="!loading && !success">
                            <div class="text-h5 text-center font-weight-bold" style="color: #000;">Cambiar contraseña</div>
                            <v-form
                                v-model="valid"
                                lazy-validation
                                @submit.prevent="submit"
                                ref="form"
                                class="mt-4"
                            >   

                            <div class="mb-4">
                                <div class="subtitle-2 font-weight-medium mb-4 text-center" style="color: #333;">
                                  La contraseña debe contener:
                                </div>
                              
                                <div class="rules-grid">
                                  <div
                                    v-for="(rule, key) in passwordRules"
                                    :key="key"
                                    class="rule-item"
                                  >
                                    <v-icon :color="rule.met ? 'green' : iconColor" small class="mr-2">
                                      {{ rule.icon }}
                                    </v-icon>
                                    <span class="text--secondary text-sm">{{ rule.label }}</span>
                                  </div>
                                </div>
                              </div>
                              
                                <v-text-field
                                    label="Contraseña"
                                    outlined
                                    dense
                                    autocomplete="off"
                                    class="mt-2"
                                    v-model="password"
                                    :type="showPassword ? 'text' : 'password'"
                                    :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                                    @click:append="showPassword = !showPassword"
                                    :rules="[rules.required, rules.min8, rules.hasUppercase, rules.hasNumber]"
                                />

                                <v-text-field
                                    label="Confirma tu contraseña"
                                    outlined
                                    dense
                                    autocomplete="off"
                                    class="mt-2"
                                    v-model="confirm_password"
                                    :type="showConfirmPassword ? 'text' : 'password'"
                                    :append-icon="showConfirmPassword ? 'mdi-eye-off' : 'mdi-eye'"
                                    @click:append="showConfirmPassword = !showConfirmPassword"
                                    :rules="[rules.required, passwordMatch]"
                                />

                                <div class="red--text" v-if="errorMessage">{{ errorMessage }}</div>

                                <v-btn
                                    style="color: white; background: #1d1d1f;"
                                    class="mt-4"
                                    rounded
                                    block
                                    :disabled="!canSubmit"
                                    type="submit"
                                >
                                    Confirmar
                                </v-btn>
                                </v-form>
                        </div>
                        <div v-if="success">
                            <div class="paragraph text-center">
                                <div class="mb-5">
                                    <v-icon color="success" size="80">mdi mdi-check-circle</v-icon>
                                </div>
                                <div>
                                  ¡Contraseña actualizada con exito!
                                </div>
                                <v-btn 
                                  block
                                  style="color: white; background: #1d1d1f;"
                                  class="mt-4"
                                  rounded
                                  :to="{name: 'login'}"
                                >Iniciar sesión</v-btn>
                            </div>
                        </div>
                        <div v-if="loading" class="text-center p-6" style="padding: 5em;">
                            <v-progress-circular
                            :size="120"
                            color="black"
                            indeterminate
                            ></v-progress-circular>
                        </div>
                    </v-card>
                </v-col>
            </v-row>
    </v-container>
    </v-main>
    </template>
    
    <script>
    
    import UserService from "../services/userService"
    
    export default {
    data: () => ({
      token: "",
      password: "",
      confirm_password: "",
      rules: {
          required: v => !!v || 'El campo es requerido',
          min8: v => (v && v.length >= 8) || 'Debe tener al menos 8 caracteres',
          hasUppercase: v => /[A-Z]/.test(v) || 'Debe contener al menos una letra mayúscula',
          hasNumber: v => /\d/.test(v) || 'Debe contener al menos un número',
          // passwordMatch: () => v => v === this.password || 'Las contraseñas no coinciden',
      },
      valid: false,
      errorsMessages: {},
      errorMessage: "",
      disabled: false,
      loading: false,
      success: false,
      showPassword: false,
      showConfirmPassword: false,
    }),

    created() {
      this.token = this.$route.params.token;
    },

    computed: {
      canSubmit() {
        return (
          this.password &&
          this.confirm_password &&
          this.password === this.confirm_password &&
          this.valid &&
          !this.disabled &&
          !this.loading
        );
      },

      iconColor() {
        return this.password || this.confirm_password ? 'grey' : 'grey lighten-1';
      },

      passwordRules() {
        return {
          min8: {
            label: 'Al menos 8 caracteres',
            met: this.password.length >= 8,
            icon: this.password.length >= 8
              ? 'mdi-check-circle'
              : (this.password ? 'mdi-close-circle-outline' : 'mdi-circle-outline'),
          },
          hasUppercase: {
            label: 'Una letra mayúscula',
            met: /[A-Z]/.test(this.password),
            icon: /[A-Z]/.test(this.password)
              ? 'mdi-check-circle'
              : (this.password ? 'mdi-close-circle-outline' : 'mdi-circle-outline'),
          },
          hasNumber: {
            label: 'Al menos un número',
            met: /\d/.test(this.password),
            icon: /\d/.test(this.password)
              ? 'mdi-check-circle'
              : (this.password ? 'mdi-close-circle-outline' : 'mdi-circle-outline'),
          },
          match: {
            label: 'Las contraseñas deben coincidir',
            met: this.password && this.confirm_password && this.password === this.confirm_password,
            icon:
              this.password && this.confirm_password && this.password === this.confirm_password
                ? 'mdi-check-circle'
                : (this.confirm_password ? 'mdi-close-circle-outline' : 'mdi-circle-outline'),
          },
        };
      },

    },

    methods: {
      async submit() {
          const isValid = await this.$refs.form.validate();

          if (!isValid || this.password !== this.confirm_password) {
              this.errorMessage = "Las contraseñas deben coincidir.";
              return;
          }

          this.errorMessage = "";
          this.loading = true;
          this.disabled = true;

          try {
              const response = await UserService.changePassword({
                  token: this.token,
                  password: this.password,
                  password_confirmation: this.confirm_password,
              });

              const result = await response.json();
              const status = response.status;

              if (status === 200) {
                this.success = true;
              } else {
                this.errorMessage = result.message;
              }
          } catch (error) {
              this.errorMessage = "Ocurrió un error inesperado.";
          }

            this.disabled = false;
            this.loading = false;
          },
      },

      passwordMatch(v) {
        return v === this.password || 'Las contraseñas no coinciden';
      },
    }
    </script>
    
    <style scoped>
    .v-main {
        background-size: cover;
        position: relative !important; 
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        background-size:cover;
        background-attachment: fixed;
        background-position: center;
        background: #1d1d1f;
        /* background-image: linear-gradient(rgba(255,255,255,0.9), rgba(255,255,255,0.9)), url('https://mangxo.com/img/bg-header.jpeg'); */
    }
    
    /* .v-card {
        color: #fff;
        box-shadow: none !important;
        background: none;
    } */

    .rules-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px 0px; /* default en desktop */
  justify-items: start;
  max-width: 400px;
  margin: 0 auto;
}

.rule-item {
  display: flex;
  align-items: center;
}

/* 👇 Mobile: compactar separación */
@media (max-width: 600px) {
  .rules-grid {
    gap: 0.5em;
    font-size: 0.6em;
  }
}


</style>