<template>
    <v-main>
        <v-container class="pa-5">
            <div class="text-h4 font-weight-bold" style="margin-bottom: 1em;">Recursos</div>
            <v-card elevation="1">
                <template>
                    <v-simple-table>
                        <template v-slot:default>
                        <tbody>
                            <tr
                                v-for="resource in resources"
                                :key="resource.id"
                            >
                                <td class="pa-3">
                                    <div class="d-flex justify-space-between">
                                        <div>
                                            <div style="display: inline;" class="font-weight-bold">{{ resource.name }} </div>
                                            <div>{{ resource.description }}</div>
                                        </div>
                                        <v-icon 
                                            v-if="resource.url"
                                            small 
                                            style="color: #000"
                                            @click="openLink(resource.url)"
                                        >mdi mdi-tray-arrow-down</v-icon>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        </template>
                    </v-simple-table>
                </template>
            </v-card>
        </v-container>
    </v-main>
</template>

<script>
    
    import PlatformResourceService from "@/services/platformResourceService.js"

    export default {
        data() {
            return {
                resources: []
            }
        },

        async created( ) {
            const response = await PlatformResourceService.get()
            const result = await response.json()

            this.resources = result
        },

        methods: {
            openLink (link) {
                window.open(link, '_blank')
            }
        }
    }
    
</script>