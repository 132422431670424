const UserService = {

    url: `${process.env.VUE_APP_API_URL}`,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },

    login: async (user) => {
        try {
            const response = await fetch(`${UserService.url}login`, {
                method: 'POST',
                body: JSON.stringify(user),
                headers: UserService.headers
            })
            return response
        } catch (e) {
            return false;
        }
    },

    getUser: async () => {
        try {
            const headers = UserService.headers
            headers['Authorization'] = 'Bearer ' + localStorage.getItem('token')

            const response = await fetch(`${UserService.url}user`, {
                headers: headers
            })
            return response
        } catch (e) {
            return false;
        }
    },

    forgotPassword: async (email) => {
        try {
            const headers = UserService.headers
            headers['Authorization'] = 'Bearer ' + localStorage.getItem('token')

            const response = await fetch(`${UserService.url}forgot-password`, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(email),
            })
            return response
        } catch (e) {
            return false;
        }
    },

    changePassword: async (data) => {
        try {
            const headers = UserService.headers
            headers['Authorization'] = 'Bearer ' + localStorage.getItem('token')

            const response = await fetch(`${UserService.url}change-password`, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(data),
            })
            return response
        } catch (e) {
            return false;
        }
    },


}

export default UserService