const RegisterService = {

    url: `${process.env.VUE_APP_API_URL}register`,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },

    step1: async (data) => {
        try {

            const response = await fetch(`${RegisterService.url}/step-1`, {
                method: 'POST',
                body: JSON.stringify(data),
                headers: RegisterService.headers
            })
            
            return response
        } catch (e) {
            return false;
        }
    },

    step2: async (data) => {
        try {

            const headers = RegisterService.headers
            // headers['Register_Token'] = localStorage.getItem('register_token')
            data["register_token"] = localStorage.getItem('register_token')

            const response = await fetch(`${RegisterService.url}/step-2`, {
                method: 'POST',
                body: JSON.stringify(data),
                headers: headers
            })
            
            return response
        } catch (e) {
            return false;
        }
    },

    step5: async (data) => {
        try {

            const headers = RegisterService.headers
            // headers['Register_Token'] = localStorage.getItem('register_token')
            data["register_token"] = localStorage.getItem('register_token')

            const response = await fetch(`${RegisterService.url}/step-5`, {
                method: 'POST',
                body: JSON.stringify(data),
                headers: headers
            })
            
            return response
        } catch (e) {
            return false;
        }
    },

    step6CompanyInfo: async (formdata) => {
        try {
           
            var myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");
            // myHeaders.append("Register_Token", localStorage.getItem('register_token'));
            formdata.append("register_token", localStorage.getItem('register_token'))

            console.log(formdata)

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            const response = await fetch(`${RegisterService.url}/step-6-general-info`, requestOptions)

            return response

        } catch (e) {  
            return false;
        }
    },

    step6BankStatments: async (formdata) => {
        try {
           
            var myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");
            // myHeaders.append("Register_Token", localStorage.getItem('register_token'));
            formdata.append("register_token", localStorage.getItem('register_token'))

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            const response = await fetch(`${RegisterService.url}/step-6-bank-statements`, requestOptions)

            return response

        } catch (e) {  
            return false;
        }
    }
}

export default RegisterService