<template>
    <v-main>
        <v-container fluid class="pa-4"> 
            <v-col cols="12">
                <div class="text-h4 font-weight-bold pb-6 pt-6" style="color:#1d1d1f;">
                    Proveedores de nuestra red
                </div>
            </v-col>
            <v-row class="pt-6">
                <v-col cols="12" md="4" lg="3" xl="2"
                    v-for="item in suppliers"
                    :key="item.id"
                    style="padding:2em;"
                >
                    <v-card
                        elevation="1"
                        height="400px"
                    >
                        <v-img
                            :src="item.logo_url"
                            dark
                            height="180px"
                            contain
                        >
                        </v-img>
                        <v-list two-line>
                            <v-list-item>
                                <v-list-item-content>
                                <v-list-item-title class="text-h7 font-weight-bold">{{ item.business_name }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-if="item.directory_vendor_phone">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-btn 
                                            block
                                            color="#1d1d1f"
                                            style="color: #fff;"
                                            @click="sendWpp(item.directory_vendor_phone, item.id)"
                                            target="_blank"
                                        >Cotizar por Whatsapp</v-btn>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-if="item.map">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-btn 
                                            block
                                            color="dark"
                                            @click="openMap(item.map, item.id)"
                                        >
                                        <v-icon small>mdi-map-marker</v-icon>
                                            Ver ubicación
                                        </v-btn>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-col>
            </v-row>
            <v-col cols="12" class="text-center">
                <v-btn
                    v-if="!loadSuppliers"
                    dense
                    outlined
                    @click="getSuppliers()"
                >
                    Ver más
                </v-btn>
                <v-progress-circular
                    v-else
                    indeterminate
                    color="#1d1d1f"
                    style="margin-left: 10px;"
                >
                </v-progress-circular>
            </v-col>
        </v-container>
        <DialogMap :visible="showMap" :map="map" @close="showMap = false"/>
        <DialogAlert 
            :visible="showDialogAlert" 
            :message="dialogAlertContent" 
            @handleCloseDialogAlert="handleCloseDialogAlert"
        />
    </v-main>
</template>

<script>
import DialogMap from '../components/DialogMap.vue'
import DialogAlert from "../components/DialogAlert"
import SupplierService from "../services/supplierService"
import DirectoryActivitiesService from "../services/directoryActivitiesService"

export default {

    components: {
        DialogMap,
        DialogAlert
    },

    data() {
        return {
            suppliers: [],
            showMap: false,
            loadSuppliers: false,
            dialogAlertContent: {
                title: "",
                success: false,
                text: ""
            },
            showDialogAlert: false
        }
    },

    async mounted() {
        const response = await SupplierService.getSuppliersToShow()
        this.suppliers = await response.json()

        await this.sendEvent({event: "directory_entry"})
    },

    methods: {
        async openMap (map, supplier_id) {
            this.showMap = true
            this.map = map

            await this.sendEvent({event: "location", supplier_id: supplier_id})
        },

        async sendWpp (phone, supplier_id) {
            const url = `https://api.whatsapp.com/send?phone=${phone}&text=¡Hola!%20Quiero%20cotizar%20materiales.`
            window.open(url, '_blank');

            await this.sendEvent({event: "contact", supplier_id: supplier_id})
        },

        async sendEvent(event) {
            await DirectoryActivitiesService.create(event)
        },

        async getSuppliers() {
            this.loadSuppliers = true
 
            const response = await SupplierService.getSuppliersToShow({page: 2})
            const status = response.status
            const result = await response.json()

            if (status == 200) {
                this.suppliers = result
            }   else {
                this.dialogAlertContent.text = result.msg
                this.showDialogAlert = true
            }

            this.loadSuppliers = false
        },

        async handleCloseDialogAlert () {
            this.showDialogAlert = false
        }
    }
}
</script>