<template>
    <v-main>
        <v-container
            class="py-8 px-6"
        >
            <v-row>
                <v-col
                lg="6"
                offset-lg="3"
                md="12"
                >
                    <v-card elevation="1" radius="20" class="pa-6 rounded-xl">
                        <v-img
                            max-height="120"
                            max-width="200"
                            src="@/assets/logo_new.png"
                            class="mx-auto"
                        ></v-img>
                        <div v-if="!loading && !success">
                            <div class="text-h5 text-center font-weight-bold" style="color: #000;">¿Olvidaste tu contraseña?</div>
                            <div class="paragraph text-center mt-5 mb-5">Ingresá tu dirección de correo electrónico. Te enviaremos un enlace para restablecer tu contraseña de forma segura.</div>
                            <v-form
                                v-model="valid"
                                lazy-validation
                                @submit.prevent="submit"
                                ref="form"
                                class="mt-4"
                            >
                            <v-text-field
                                label="Email"
                                outlined
                                dense
                                autocomplete="off"
                                class="mt-2"
                                v-model="email"
                                :rules="[rules.required, rules.email]"
                                :error-messages="errorsMessages.email ? errorsMessages.email[0] : ''"
                            />
                                <div class="red--text" v-if="errorMessage">{{ errorMessage }}</div>
                                <v-btn 
                                    style="color: white; background: #1d1d1f;" 
                                    class="mt-4" 
                                    type="submit" 
                                    rounded 
                                    block
                                >Confirmar</v-btn>
                            </v-form>
                        </div>
                        <div v-if="success">
                            <div class="paragraph text-center">
                                <div class="mb-5">
                                    <v-icon color="success" size="80">mdi mdi-check-circle</v-icon>
                                </div>
                                Te enviamos un correo con un enlace para restablecer tu contraseña. Revisa tu bandeja de entrada (y la carpeta de spam por si acaso).
                            </div>
                        </div>
                        <div v-if="loading" class="text-center p-6" style="padding: 5em;">
                            <v-progress-circular
                            :size="120"
                            color="black"
                            indeterminate
                            ></v-progress-circular>
                        </div>
                    </v-card>
                </v-col>
            </v-row>
    </v-container>
    </v-main>
    </template>
    
    <script>
    
    import UserService from "../services/userService"
    
    export default {
      data: () => ({
            email: "",
            rules: {
                required: v => !!v || 'El campo es requerido',
                email: v => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || 'El correo no es válido',
            },
            valid: false,
            errorsMessages: {},
            errorMessage: '',
            disabled: false,
            loading: false,
            success: false
      }),
    
      created() {
            this.showWelcomeMessage = this.$route.params.showMsg
      },
    
      methods: {
        async submit() {
            // alert("AS")
    
            const isValid = await this.$refs.form.validate();

            if (!isValid) {
                return;
            }
            
            this.loading = true
            this.disabled = true    
            
            const response = await UserService.forgotPassword({email: this.email})
            const status = response.status
            const result = await response.json()
            
            if (status == 200) {
                this.success = true
            } else {
                this.errorMessage = result.message
            }

            this.disabled = false
            this.loading = false
        },
      }
    }
    </script>
    
    <style scoped>
    .v-main {
        background-size: cover;
        position: relative !important; 
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        background-size:cover;
        background-attachment: fixed;
        background-position: center;
        background: #1d1d1f;
        /* background-image: linear-gradient(rgba(255,255,255,0.9), rgba(255,255,255,0.9)), url('https://mangxo.com/img/bg-header.jpeg'); */
    }
    
    /* .v-card {
        color: #fff;
        box-shadow: none !important;
        background: none;
    } */
    </style>