const PlatformResourceService = {
    async get () {
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
        return await fetch(`${process.env.VUE_APP_API_URL}platform-resources`, { headers });
    },
}

export default PlatformResourceService;
